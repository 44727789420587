import { Autocomplete, ListboxOption, StarMd } from '@construct-kit/core';
import { useState } from 'react';
import styled from 'styled-components';

const StyledAutocomplete = styled(Autocomplete)`
  ul {
    width: 200%;
    border-top: 0.125rem solid var(--ck-borderFocused);
    border-radius: 0rem 0rem 0rem 0rem;
    margin-top: 1px;
  }

  div[role='combobox'] {
    border-radius: 0.5rem !important;
  }
`;

const GetTargetDetails = ({ getTargetDetails }) => {
  const [targetOptions, setTargetOptions] = useState([]);
  const TARGET_SUGGEST_API = `${process.env.REACT_APP_QUICK_AVAILS_API}/auto_complete_targeting`;

  const fetchTargetSuggestions = (targetQuery) => {
    const postData = { query: targetQuery };
    let urlparms = new URLSearchParams(postData).toString();
    const url = `${TARGET_SUGGEST_API}`;

    return fetch(url + '?' + urlparms)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  };

  const onInputChange = (value) => {
    if (value && value.length > 2) {
      fetchTargetSuggestions(value).then((fetchedSuggestions) => {
        setTargetOptions(fetchedSuggestions);
      });
    }
  };

  const mapOptionToReact = ({
    option,
    isActive,
    sizeVariant,
    inputValue,
    mapOptionToString,
  }) => {
    if (option.type === 'sub') {
      return (
        <ListboxOption isActive={false} sizeVariant={sizeVariant}>
          <span className="target-icon suburb" />
          {mapOptionToString(option)}
        </ListboxOption>
      );
    }
    if (option.type === 'target') {
      return (
        <ListboxOption isActive={false} sizeVariant={sizeVariant}>
          <span className="target-icon target" />
          {mapOptionToString(option)}
        </ListboxOption>
      );
    }
    if (option.type === 'proptype') {
      return (
        <ListboxOption isActive={false} sizeVariant={sizeVariant}>
          <span className="target-icon proptype"></span>
          {mapOptionToString(option)}
        </ListboxOption>
      );
    }

    return (
      <ListboxOption isActive={isActive} sizeVariant={sizeVariant}>
        <StarMd /> {mapOptionToString(option)}
      </ListboxOption>
    );
  };
  const mapOptionToStr = (option) => {
    if (!option) return '';
    return option.value.toString();
  };

  return (
    <div className="topInputContainer">
      <StyledAutocomplete
        id="targeting"
        name="target"
        label="Get target details:"
        placeholder="Target Name"
        onInputChange={onInputChange}
        filterOptions={(e) => {
          return e;
        }}
        listboxMaxHeight="40rem"
        mapOptionToListNode={mapOptionToReact}
        onSelectedOptionChange={getTargetDetails}
        mapOptionToString={mapOptionToStr}
        options={targetOptions}
        sizeVariant="small"
        tooltip={
          <p>
            <span className="target-icon proptype" />- Proptype
            <br />
            <span className="target-icon suburb" />- Suburb
            <br />
            <span className="target-icon target" />- Targeting
          </p>
        }
      />
    </div>
  );
};
export default GetTargetDetails;
