import { Autocomplete, ListboxOption, StarMd } from '@construct-kit/core';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledAutocomplete = styled(Autocomplete)`
  ul {
    width: 200%;
    border-top: 0.125rem solid var(--ck-borderFocused);
    border-radius: 0rem 0rem 0rem 0rem;
    margin-top: 1px;
  }

  div[role='combobox'] {
    border-radius: 0.5rem !important;
  }
`;

const TargetingBox = ({ id, idx, name, value, handleChange, disabled }) => {
  const [targetOptions, setTargetOptions] = useState([]);
  const [targetInput, setTargetInput] = useState(value);

  const TARGET_SUGGEST_API = `${process.env.REACT_APP_QUICK_AVAILS_API}/auto_complete_targeting`;

  useEffect(() => {
    setTargetInput(value);
  }, [value]);

  const fetchTargetSuggestions = (targetQuery) => {
    const postData = { query: targetQuery };
    let urlparms = new URLSearchParams(postData).toString();
    const url = `${TARGET_SUGGEST_API}`;

    return fetch(url + '?' + urlparms)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  };

  const onInputChange = (value) => {
    if (value && value.length > 2) {
      fetchTargetSuggestions(value).then((fetchedSuggestions) => {
        setTargetOptions(fetchedSuggestions);
      });
    }
    setTargetInput(value);
  };

  const mapOptionToReact = ({
    option,
    isActive,
    sizeVariant,
    inputValue,
    mapOptionToString,
  }) => {
    if (option.type === 'sub') {
      return (
        <ListboxOption isActive={false} sizeVariant={sizeVariant}>
          <span title={mapOptionToString(option)}>
            <span className="target-icon suburb"></span>
            {mapOptionToString(option)}
          </span>
        </ListboxOption>
      );
    }
    if (option.type === 'target') {
      return (
        <ListboxOption isActive={false} sizeVariant={sizeVariant}>
          <span title={mapOptionToString(option)}>
            <span className="target-icon target"></span>
            {mapOptionToString(option)}
          </span>
        </ListboxOption>
      );
    }
    if (option.type === 'proptype') {
      return (
        <ListboxOption isActive={false} sizeVariant={sizeVariant}>
          <span title={mapOptionToString(option)}>
            <span className="target-icon proptype"></span>
            {mapOptionToString(option)}
          </span>
        </ListboxOption>
      );
    }

    return (
      <ListboxOption
        title={mapOptionToString(option)}
        isActive={isActive}
        sizeVariant={sizeVariant}
      >
        <span title={mapOptionToString(option)}>
          <StarMd /> {mapOptionToString(option)}
        </span>
      </ListboxOption>
    );
  };
  const mapOptionToStr = (option) => {
    if (!option) return '';
    return option.value.toString();
  };

  return (
    <StyledAutocomplete
      id={id}
      name={name}
      hideLabel={true}
      label={name}
      placeholder={'Target Name'}
      onInputChange={onInputChange}
      filterOptions={(e) => {
        return e;
      }}
      listboxMaxHeight="40rem"
      mapOptionToListNode={mapOptionToReact}
      onSelectedOptionChange={handleChange(name, idx)}
      mapOptionToString={mapOptionToStr}
      options={targetOptions}
      inputValue={targetInput}
      sizeVariant="small"
      disabled={disabled}
    />
  );
};
export default TargetingBox;
