import Body from './components/Body';

import { ConstructKit, Pangea } from '@construct-kit/core';

const App = () => {
  return (
    <ConstructKit>
      <Pangea />

      <Body />
    </ConstructKit>
  );
};

/* class App extends React.Component {
  render () {
    return <h1>hello from a classy dude</h1>
  }

} */
export default App;
